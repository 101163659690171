<template>
  <div class="myApplication">
    <div class="search">
      <div class="search-l">
        <div>
          <v-select
            label="社区列表"
            :options="tenantList"
            v-model="tenantId"
          ></v-select>
        </div>
        <div>
          <v-button :width="112" text="搜索" @click="search"></v-button>
        </div>
      </div>
      <!-- <div class="search-r">
        <v-button :width="112" text="新建" @click="addApply"></v-button>
      </div> -->
    </div>
    <v-table
      :tableData="tableData"
      :headers="headers"
      operateColumnAlign="center"
    >
      <template #operateSlot="scope">
        <div class="operate">
          <v-button
            type="text"
            text="编辑"
            @click="editData(scope.row)"
          ></v-button>
          <v-button
            type="text"
            text="查看"
            @click="viewData(scope.row)"
          ></v-button>
          <!-- <v-button type="text" text="导出"></v-button> -->
        </div>
      </template>
    </v-table>
  </div>
</template>

<script>
import {
  getTenantInfoList,
  getTenantList,
  getTenantInfoDetails,
  updateImplementation,
} from "./api.js";
import {} from "./map";
import { _localStorage } from "@/utils/utils.js";
import { mapState } from "vuex";
export default {
  name: "myApplication",
  data() {
    return {
      searchParam: {
        tenantId: "",
      },
      headers: [
        {
          prop: "tenantName",
          label: "社区名称",
          align: "center",
        },
        {
          prop: "futureCommunityCode",
          label: "未来社区编码",
          align: "center",
        },
      ],
      tableData: [], //表格数据
      tenantList: [],
      tenantId: "",
    };
  },
  computed: {
    ...mapState("app", ["accountInfo"]),
  },
  async created() {
    if (_localStorage.getItem("userInfo")) {
      let userInfo = JSON.parse(_localStorage.getItem("userInfo"));
      this.$store.commit("app/setAccountInfo", userInfo);
    }
    await this.getTenantList();
    // await this.getTenantInfoList();
  },
  mounted() {},
  methods: {
    //搜索
    search() {
      this.getTenantList();
    },
    //编辑
    editData(row) {
      this.$router.push({
        name: "zZTSSApplyListSheet",
        query: {
          id: row.id,
          isEdit: true,
        },
      });
    },
    //查看
    viewData(row) {
      this.$router.push({
        name: "zZTSSApplyListSheet",
        query: {
          id: row.id,
          isEdit: false,
        },
      });
    },
    //获取社区编码列表
    async getTenantList() {
      const params = {
        tenantId: this.accountInfo.tenantId,
      };
      const res = await this.$axios.post(
        getTenantList,
        this.$qs.stringify(params)
      );
      if (res.code == 200) {
        this.tenantList = [];
        const { data } = res;
        this.tableData = data;
        data.forEach((ele) => {
          let obj = {};
          obj.label = ele.tenantName;
          obj.value = ele.tenantId;
          this.tenantList.push(obj);
        });
        this.tenantId = this.tenantList[0]?.value;
      }
    },
    //获取社区列表
    getTenantInfoList() {
      const params = {
        tenantId: this.tenantId,
      };
      this.$axios.get(`${getTenantInfoList}`, { params }).then((res) => {
        if (res.code == 200) {
          this.tableData = [];
          const { data } = res;

          this.tableData.push(data);
        }
      });
    },
    //新建
    addApply() {
      this.$router.push({
        name: "zZTSSApplyListSheet",
      });
    },
  },
};
</script>

<style lang="less" scoped>
.myApplication {
  height: 100%;
  padding-top: 50px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  .search {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
    .search-l {
      flex: 1;
      display: flex;
      align-items: center;
      ::v-deep .v-control {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        margin: 5px 20px 5px 0;
        margin-right: 20px;
      }
    }
  }
  .v-table {
    height: calc(100% - 110px);
    /deep/ .operate {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-wrap: wrap;
      .v-control {
        margin: 0;
      }
      .el-button {
        padding: 4px;
      }
    }
  }
}
</style>
